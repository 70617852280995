import { Reply } from "./Reply";
import { FC } from "react";
import { Comment, JWToken } from "../../types";

type Props = {
    commentData: Comment[],
    user: JWToken
}

export const ReplyContainer: FC<Props> = ({commentData, user}) => {
    return (
        <div className="reply-container">
            {commentData.map((data: Comment) => (
                <Reply
                    key={data.id}
                    commentData={data}
                    user={user}
                />
            ))}
        </div>
    );
};

export default ReplyContainer;
