import { useEffect, useState } from 'react';
import { Activity, GroupedActivities } from '../types';
import axios from 'axios';
import dayjs from 'dayjs';
import { Avatar, Box, Button, Typography, alpha } from '@mui/material';
import { Md5 } from 'ts-md5';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import '../style/activityFeedRework.scss';
import { useSearchParams } from 'react-router-dom';

dayjs.extend(customParseFormat);

export const ActivityFeedRework = () => {
  const [activities, setActivities] = useState<GroupedActivities>({});
  const [params] = useSearchParams();
  const bigScreenMode = params.get('big') === 'true';

  const today = dayjs().format('DD/MM/YYYY');
  const yesterday = dayjs().subtract(1, 'days').format('DD/MM/YYYY');

  useEffect(() => {
    (async () => {
      const response = await axios.get<Activity[]>(
        `${process.env.REACT_APP_BACKEND_URL!}activity/`
      );
      setActivities(
        // @ts-expect-error - no official support for groupBy yet
        Object.groupBy(response.data, (activity) =>
          dayjs(activity.timeCreated).format('DD/MM/YYYY')
        )
      );
    })();
  }, []);

  const handleActionClick = (url: string) => {
    const navigateMessage = {
      messageType: 'navigate',
      payload: {
        appName: 'collab',
        route: url,
      },
    };

    window.parent.postMessage(navigateMessage, '*');
  };

  const activityTitle = (title: string) => {
    if (title.includes('blog')) {
      return 'New blog post';
    }
    if (title.includes('skill')) {
      return 'New skill added';
    }
    return title;
  };

  return (
    <Box
      sx={{
        width: bigScreenMode ? undefined : '97%',
        pt: bigScreenMode ? 2 : 5,
        px: bigScreenMode ? 2 : undefined,
      }}
    >
      {Object.keys(activities).map((key) => (
        <Box key={key}>
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: '1rem', sm: '1.125rem' },
              fontWeight: bigScreenMode ? 900 : 500,
              lineHeight: 1,
              mb: 2,
              color: bigScreenMode ? 'white' : undefined,
            }}
          >
            {key === today
              ? 'Today'
              : key === yesterday
              ? 'Yesterday'
              : dayjs(key, 'DD/MM/YYYY').format('D MMM, YYYY')}
          </Typography>
          {activities[key].map((activity, i) => (
            <Box
              id="activity-box"
              key={activity.id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                lineHeight: '1.25rem',
                alignItems: 'flex-start',
                mt: i === 0 ? 0 : '.5rem',
                mb: activities[key].length - 1 === i ? '1.25rem' : 0,
                gap: '0.5rem',
                width: '94%',
                border: '1px solid #E0E0E0',
                borderRadius: '10px',
                paddingX: '1rem',
                paddingY: '0.75rem',
                backgroundColor: bigScreenMode ? 'white' : undefined,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Avatar
                  alt={`${activity.consultant.firstName}'s avatar`}
                  src={`https://www.gravatar.com/avatar/${Md5.hashStr(
                    activity.consultant.email
                  )}?s=80&r=g&d=retro`}
                />
                <Box>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: 700, fontSize: { xs: '1.375rem', sm: '1.5rem' } }}
                  >
                    {activityTitle(activity.title)}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: '#898989', fontSize: { xs: '0.875rem', sm: '1rem' } }}
                  >
                    {dayjs(activity.timeCreated).format('HH:MM')} by {activity.consultant.firstName}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ width: '100%' }}>
                <Typography
                  variant="body1"
                  sx={{ color: '#898989', fontSize: { xs: '0.875rem', sm: '1rem' } }}
                >
                  {activity.description}
                </Typography>

                {!bigScreenMode && (
                  <Button
                    variant="text"
                    sx={{
                      float: 'right',
                      mt: '0.25rem',
                      color: '#011d41',
                      backgroundColor: '#1976D20A',
                      '&:hover': {
                        backgroundColor: alpha('#1C84FF', 0.15),
                      },
                      fontSize: { xs: '0.750rem', sm: '0.875rem' },
                    }}
                    onClick={() => handleActionClick(activity.url)}
                  >
                    {activity.actionText}
                  </Button>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};
