import React, { FC, useEffect, useState } from 'react';
import { Event, Topic } from '../types';
import axios from 'axios';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Switch from '@mui/material/Switch';
import { jsonTemplate } from '../constants';
import { EventRow } from './EventRow';
import { DeleteDialog } from './DeleteDialog';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DeleteOutline } from '@mui/icons-material';

type PropsRow = {
  inputTopic: Topic;
  fetchTopics: () => void;
  showToast: (message: string) => void;
};

export const Row: FC<PropsRow> = ({ inputTopic, fetchTopics, showToast }) => {
  const [open, setOpen] = useState(false);
  const [events, setEvents] = useState<Event[]>(inputTopic.applicationEvents);
  const [topic, setTopic] = useState<Topic>(inputTopic);

  useEffect(() => {
    setEvents(inputTopic.applicationEvents);
  }, [inputTopic.applicationEvents]);

  const setActive = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}topic/${topic.id}/active/`
    );
    if (response.status >= 200 && response.status < 300) {
      setTopic({ ...topic, active: !topic.active });
    } else {
      console.error(response);
    }
  };

  const deleteTopic = async () => {
    setOpen(false);
    const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}topic/${topic.id}/`);
    if (response.status >= 200 && response.status < 300) {
      showToast(`Topic "${topic.topic}" succesvol verwijderd`);
      fetchTopics();
    } else {
      console.error(response);
    }
  };

  const cancelAddEvent = () => {
    setEvents((prevEvents) => prevEvents.slice(0, -1));
  };

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset', backgroundColor: '#1976D20A' } }}>
        <TableCell component="th" scope="row" sx={{ width: '120px' }}>
          <Switch onChange={setActive} color="default" checked={topic.active} />
        </TableCell>
        <TableCell sx={{ fontSize: 20, fontWeight: 'bold' }}>{topic.topic}</TableCell>
        <TableCell align="right">
          <IconButton>
            <AddIcon
              onClick={() =>
                setEvents([
                  ...events,
                  {
                    id: 0,
                    key: '',
                    message: jsonTemplate,
                    active: true,
                  },
                ])
              }
            />
          </IconButton>
          <IconButton onClick={() => setOpen(true)}>
            <DeleteOutline />
          </IconButton>
        </TableCell>
      </TableRow>
      {events.length !== 0 &&
        events.map((event: Event) => (
          <EventRow
            key={event.id}
            inputEvent={event}
            topicId={topic.id}
            fetchTopics={fetchTopics}
            cancelAddEvent={cancelAddEvent}
            showToast={showToast}
          />
        ))}
      <DeleteDialog open={open} setOpen={setOpen} name={topic.topic} deleteFunc={deleteTopic} />
    </React.Fragment>
  );
};
