import React, { FC, useState } from 'react';
import { CommentFooter } from './CommentFooter';
import { CommentHeader } from './CommentHeader';
import ReplyContainer from './ReplyContainer';
import AddComment from './AddComment';
import '../../style/comment.scss';
import { JWToken, Thread } from '../../types';
import axios from 'axios';
import { AUTHID } from '../../constants';

type Props = {
  commentData: Thread,
  user: JWToken,
  resourceId: number,
  updatePage: () => void
}

export const CommentBox: FC<Props> = ({ commentData, user, resourceId, updatePage }) => {
  const [replying, setReplying] = useState(false);

  const addReply = (newReply: string) => {
    axios.post(`resource/${resourceId}/thread/${commentData.id}/comment/`, {
      'consultantAuth0Id': user[AUTHID],
      'message': newReply,
    }).then(updatePage);
    setReplying(false);
  };

  return (
    <div
      className={`comment-container ${
        Boolean(commentData.subComments.length) ? 'reply-container-gap' : ''
      }`}
    >
      <div className="comment">
        <div className="comment--body">
          <CommentHeader
            commentData={commentData.threadStartingComment}
            setReplying={setReplying}
            user={user}
            isReply={false}
          />
          <div className="comment-content">{commentData.threadStartingComment.message}</div>
        </div>
        <CommentFooter
          setReplying={setReplying}
        />
      </div>

      {replying && (
        <AddComment
          user={user}
          buttonValue={'reply'}
          addComments={addReply}
          replyingTo={commentData.threadStartingComment.commentingConsultant.firstName}
        />
      )}
      {Boolean(commentData.subComments.length) && (
        <ReplyContainer
          commentData={commentData.subComments}
          user={user}
        />
      )}
    </div>
  );
};
