export const ROLES = 'https://schemas.quickstarts.com/roles/roles';
export const NAME = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name';
export const EMAIL = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress';
export const AUTHID = 'sub';

export const jsonTemplate: string = '{\n' +
  '        "slack": [\n' +
  '          {\n' +
  '            "channel": "",\n' +
  '            "text": ""\n' +
  '          }\n' +
  '        ],\n' +
  '        "mail": [\n' +
  '          {\n' +
  '            "subject": "",\n' +
  '            "text": ""\n' +
  '          }\n' +
  '        ],\n' +
  '        "notification": {\n' +
  '          "consultantAuth0Id": "",\n' +
  '          "title": ".",\n' +
  '          "description": "",\n' +
  '          "url": "",\n' +
  '          "actionText": ""\n' +
  '        },\n' +
  '        "activity": {\n' +
  '          "title": "",\n' +
  '          "description": "",\n' +
  '          "url": "",\n' +
  '          "actionText": ""\n' +
  '        }\n' +
  '}'
