import { FC, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import { Event } from '../types';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import { DeleteDialog } from './DeleteDialog';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { DeleteOutline } from '@mui/icons-material';

type Props = {
  inputEvent: Event;
  topicId: number;
  fetchTopics: () => void;
  cancelAddEvent: () => void;
  showToast: (message: string) => void;
};

export const EventRow: FC<Props> = ({
  inputEvent,
  topicId,
  fetchTopics,
  cancelAddEvent,
  showToast,
}) => {
  const [event, setEvent] = useState<Event>(inputEvent);
  const [open, setOpen] = useState(event.key.length === 0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const deleteEvent = async () => {
    setOpenDeleteDialog(false);
    const response = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}topic/${topicId}/event/${event.id}/`
    );
    if (response.status >= 200 && response.status < 300) {
      showToast(`Event "${event.key}" succesvol verwijderd`);
      fetchTopics();
    } else {
      console.error(response);
    }
  };

  const setActive = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}topic/${topicId}/event/${event.id}/active/`
    );
    if (response.status >= 200 && response.status < 300) {
      setEvent({ ...event, active: !event.active });
    } else {
      console.error(response);
    }
  };

  const addEvent = async () => {
    if (event.key.length !== 0 && event.message.length !== 0) {
      const response = await axios.post<Event>(
        `${process.env.REACT_APP_BACKEND_URL}topic/${topicId}/event/`,
        {
          key: event.key,
          message: event.message,
          active: event.active,
        }
      );
      showToast(`Event "${response.data.key}" succesvol toegevoegd`);
      fetchTopics();
    }
  };

  const editEvent = async () => {
    await axios.post(`${process.env.REACT_APP_BACKEND_URL}topic/${topicId}/event/${event.id}/`, {
      key: event.key,
      message: event.message,
      active: event.active,
    });
    showToast('Wijzigingen opgeslagen');
  };

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <Switch onChange={setActive} color="default" checked={event.active} />
        </TableCell>
        <TableCell>{event.key}</TableCell>
        <TableCell align="right">
          {event.id === 0 ? (
            <Button
              sx={{ marginLeft: 6 }}
              variant="outlined"
              color="error"
              onClick={cancelAddEvent}
              startIcon={<CancelIcon />}
            >
              Cancel
            </Button>
          ) : (
            <IconButton onClick={() => setOpenDeleteDialog(true)}>
              <DeleteOutline />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ display: 'flex', flexDirection: 'column', pt: 1 }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <TextField
                  onChange={(e) => setEvent({ ...event, key: e.target.value })}
                  sx={{ flexGrow: 1 }}
                  id="outlined-basic"
                  label="Event"
                  variant="outlined"
                  defaultValue={event.key}
                  autoFocus={event.key.length === 0}
                />
                {event.id === 0 ? (
                  <Button
                    onClick={addEvent}
                    variant="outlined"
                    startIcon={<AddIcon fontSize="large" />}
                  >
                    Add event
                  </Button>
                ) : (
                  <Button
                    onClick={editEvent}
                    variant="outlined"
                    startIcon={<SaveIcon fontSize="large" />}
                  >
                    Save event
                  </Button>
                )}
              </Box>
              <TextField
                onChange={(e) => setEvent({ ...event, message: e.target.value })}
                margin="normal"
                id="outlined-multiline-static"
                label="JSON"
                multiline
                rows={10}
                fullWidth
                defaultValue={event.message}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <DeleteDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        name={event.key}
        deleteFunc={deleteEvent}
      />
    </>
  );
};
