import React, { FC } from 'react';
import { Comment, JWToken } from '../../types';
import { EMAIL } from '../../constants';
import { Md5 } from 'ts-md5';
import dayjs from 'dayjs';
import Avatar from '@mui/material/Avatar';


type Props = {
  commentData: Comment,
  user: JWToken,
  isReply: boolean,
  setReplying?: (active: boolean) => void
}

export const CommentHeader: FC<Props> = ({ commentData, user, isReply, setReplying }) => {
  return (
    <div className="comment--header">
      <Avatar className="profile-pic" alt="Remy Sharp"
              src={`https://www.gravatar.com/avatar/${Md5.hashStr(commentData.commentingConsultant.email)}?s=500&r=g&d=retro`}/>
      <div className="username">{commentData.commentingConsultant.firstName}</div>
      {commentData.commentingConsultant.email === user[EMAIL] ? <div className="you-tag">you</div> : ''}
      <div className="comment-posted-time">{`${dayjs(commentData.timeCreated).format('ddd MMM D[th] HH:mm')}`}</div>
    </div>
  );
};

