import { CSSProperties } from "react";
import styled from "@emotion/styled";

export const Content = styled('div')({
  padding: "2rem 0 2rem 0",
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column'
})
export const StyledDiv = styled('div')({
  cursor: 'pointer',
});

export const baseStyle: CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

export const focusedStyle: CSSProperties = {
  borderColor: '#2196f3'
};

export const acceptStyle: CSSProperties = {
  borderColor: '#00e676'
};

export const rejectStyle: CSSProperties = {
  borderColor: '#ff1744'
};

export const thumbsContainer: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
  justifyContent: 'center',
  width: '100%'
};

export const thumb: CSSProperties = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  maxWidth: 200,
  // maxHeight: 200,
  // padding: 4,
  boxSizing: 'border-box',
};

export const thumbInner: CSSProperties = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

export const img: CSSProperties = {
  display: 'block',
  width: '100%'
};