import { useCallback, useEffect, useState } from 'react';
import { CommentBox } from './comments/CommentBox';
import { AddComment } from './comments/AddComment';
import '../style/app.scss';
import { Resource, JWToken } from '../types';
import axios from 'axios';
import '../style/commentsPage.scss';
import jwtDecode from 'jwt-decode';
import { AUTHID } from '../constants';

interface CommentsPageProps {
  accessToken: string;
}

export const CommentsPage = ({ accessToken }: CommentsPageProps) => {
  const [resource, setResource] = useState<null | Resource>(null);
  const [resourceURL, setResourceURL] = useState('');

  const fetchComments = useCallback((id: number) => {
    console.log('fetching comments', id);
    axios.get<Resource>(`/resource/${id}/`).then((r) => setResource(r.data));
  }, []);

  useEffect(() => {
    if (accessToken) {
      window.parent.postMessage(
        {
          messageType: 'readyComments',
        },
        '*'
      );
    }

    const handler = (event: any) => {
      if (event.data.messageType !== 'commentsResource') return;
      if (event.data.payload.resource.collabResourceId !== 0) {
        fetchComments(event.data.payload.resource.collabResourceId);
      } else {
        setResourceURL(event.data.payload.resourceURL);
      }
    };

    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, [accessToken, fetchComments]);

  const addComments = async (newComment: string) => {
    if (!resource) {
      const response = await addResource();
      setResource(response.data);
      const responseComment = await postComment(response.data.id, newComment);
      if (responseComment?.status === 200) {
        fetchComments(response.data.id);
      }
    } else {
      const responseComment = await postComment(resource.id, newComment);
      if (responseComment?.status === 200) {
        fetchComments(resource.id);
      }
    }
  };

  const handleLike = async () => {
    if (!resource) {
      const response = await addResource();
      setResource(response.data);
      const responseLike = await postLike(response.data.id);
      if (responseLike?.status === 200) {
        fetchComments(response.data.id);
      }
    } else {
      const responseLike = await postLike(resource.id);
      if (responseLike?.status === 200) {
        fetchComments(resource.id);
      }
    }
  };

  const postLike = async (id: number) => {
    if (accessToken) {
      return axios.post(`resource/${id}/react`, {
        consultantAuth0Id: jwtDecode<JWToken>(accessToken)[AUTHID],
        reactionType: 'like',
      });
    }
  };

  const addResource = async () => {
    return axios.post<Resource>(`resource/`, {
      url: resourceURL,
    });
  };

  const postComment = async (id: number, newComment: string) => {
    if (accessToken) {
      return axios.post(`resource/${id}/thread/`, {
        consultantAuth0Id: jwtDecode<JWToken>(accessToken)[AUTHID],
        message: newComment,
      });
    }
  };

  const updatePage = () => {
    if (resource) {
      fetchComments(resource.id);
    }
  };

  return (
    <div className={'App'}>
      {resource?.threads.map((comment) => (
        <CommentBox
          key={comment.id}
          commentData={comment}
          user={jwtDecode<JWToken>(accessToken)}
          resourceId={resource.id}
          updatePage={updatePage}
        />
      ))}
      <AddComment
        user={jwtDecode<JWToken>(accessToken)}
        buttonValue={'send'}
        addComments={addComments}
        replyingTo={null}
        reactions={resource?.reactions}
        handleLike={handleLike}
      />
    </div>
  );
};
