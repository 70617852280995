import { FC, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
// NOTE: After v4.0.0, styled components exposes a ref using forwardRef,
// therefore, no need for using innerRef as refKey
import {
  acceptStyle,
  baseStyle,
  focusedStyle,
  rejectStyle,
  StyledDiv,
  Content,
  thumb,
  img,
  thumbsContainer,
} from './fileUpload/styles';
import { Alert, AlertColor, Button, Grid } from '@mui/material';
import axios from 'axios';

type Props = {};
type FileWithPreview = File & { preview: string };

export const FileUploadPage: FC<Props> = () => {
  const [file, setFile] = useState<FileWithPreview | null>(null);
  const [showAlert, setShowAlert] = useState<AlertColor | undefined>(undefined);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      setFile(Object.assign(file, { preview: URL.createObjectURL(file) }));
    },
    accept: {
      'image/*': [],
    },
    maxFiles: 1,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => {
      if (file) URL.revokeObjectURL(file.preview);
    };
  });

  const uploadFile = async (file: FileWithPreview) => {
    const { name, type } = file;

    try {
      const presignResponse = await axios.get(`/attachment/signedUrl?name=${name}&type=${type}`);
      if (presignResponse.status !== 200) return;
      const uploadResponse = await axios.put(presignResponse.data, file, {
        headers: {
          'Content-Type': type,
          'x-amz-acl': 'public-read',
          Authorization: '',
        },
      });
      if (uploadResponse.status === 200) {
        const url = presignResponse.data.split('?')[0];
        await navigator.clipboard.writeText(url);

        const UploadMessage = {
          messageType: 'fileUploaded',
          payload: {
            url,
          },
        };

        window.parent.postMessage(UploadMessage, '*');
        setShowAlert('success');
      }
    } catch (e) {
      setShowAlert('error');
      console.error(e);
    }
  };

  const reset = () => {
    setFile(null);
    setShowAlert(undefined);
  };

  return (
    <Content>
      {file ? (
        <>
          {showAlert && (
            <Alert severity={showAlert || 'info'}>
              {showAlert === 'success'
                ? 'File uploaded!\nThe image location has been copied to your clipboard '
                : 'Something went wrong.'}
            </Alert>
          )}
          <div style={thumbsContainer}>
            <div style={thumb} key={file.name}>
              <img
                src={file.preview}
                alt="Upload file preview"
                style={img}
                // Revoke data uri after image is loaded
                onLoad={() => {
                  URL.revokeObjectURL(file.preview);
                }}
              />
            </div>

            <Grid container justifyContent="space-evenly" style={{ marginTop: '2rem' }}>
              <Grid item>
                <Button variant="outlined" onClick={() => reset()}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={() => uploadFile(file)}>
                  Upload
                </Button>
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <StyledDiv {...getRootProps({ refKey: 'innerRef', style })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop a file here, or click to select a file</p>
        </StyledDiv>
      )}
    </Content>
  );
};
