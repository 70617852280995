import React, { FC } from 'react';
import ReplyIcon from '@mui/icons-material/Reply';

type Props = {
  setReplying: (value: (((prevState: boolean) => boolean) | boolean)) => void,
}

export const CommentFooter: FC<Props> = ({ setReplying }) => {
  const showAddComment = () => {
    setReplying(prevState => !prevState);
  };
  return (
    <div className="comment--footer">
      <div className="comment--btn">
        <button
          className={`reply-btn`}
          onClick={showAddComment}
        >
          <ReplyIcon fontSize="small"/> Reply
        </button>
      </div>
    </div>
  );
};
