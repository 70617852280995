import React, { FC } from 'react';
import Grid from '@mui/material/Grid';

type Props = {};

export const NoAccess: FC<Props> = () => {
  return (
    <Grid container alignItems="center" justifyContent="center">
      <img src={'assets/PNG/access.png'} alt={'foto no access'} />
    </Grid>
  );
};
