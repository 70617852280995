import { FC } from 'react';
import '../../style/comment.scss';
import { CommentHeader } from './CommentHeader';
import { Comment, JWToken } from '../../types';

type Props = {
  commentData: Comment,
  user: JWToken
}

export const Reply: FC<Props> = ({ commentData, user }) => {
  const text = commentData.message.trim().split(' ');

  return (
    <div className={`comment-container`}>
      <div className="comment">
        <div className="comment--body">
          <CommentHeader
            commentData={commentData}
            user={user}
            isReply={true}
          />
          <div className="comment-content">
            <span className="replyingTo">{text.shift()?.slice(0,-1)}</span>
            {text.join(' ')}
          </div>;
        </div>
      </div>
    </div>
  );
};