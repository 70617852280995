import React, { FC, useState } from 'react';
import '../../style/addComment.scss';
import { Md5 } from 'ts-md5';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import { JWToken, Reaction } from '../../types';
import AvatarGroup from '@mui/material/AvatarGroup';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Divider from '@mui/material/Divider';
import { AUTHID } from '../../constants';
import { Typography } from '@mui/material';

type Props = {
  user: JWToken;
  buttonValue: string;
  addComments: (newReply: string) => void;
  replyingTo: string | null;
  reactions?: Reaction[];
  handleLike?: () => void;
};

export const AddComment: FC<Props> = ({
  user,
  buttonValue,
  addComments,
  replyingTo,
  reactions,
  handleLike,
}) => {
  const replyingToUser = replyingTo ? `@${replyingTo}, ` : '';
  const [comment, setComment] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const clickHandler = () => {
    if (comment.trim() === '') return;
    const newComment: string = replyingToUser + comment;
    addComments(newComment);
    setComment('');
  };

  const liked = reactions?.some((r: Reaction) => r.reactingConsultant.auth0Id === user[AUTHID]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const COMMENT_CHAR_LIMIT = 600;

  return (
    <div className="add-comment">
      <textarea
        className="comment-input"
        placeholder="Add a comment"
        value={replyingToUser + comment}
        onChange={(e) => {
          if (e.target.value.length > COMMENT_CHAR_LIMIT) return;
          setComment(e.target.value.replace(replyingTo ? `@${replyingTo}, ` : '', ''));
        }}
      />
      <Typography
        variant="caption"
        sx={{
          color: comment.length === COMMENT_CHAR_LIMIT ? '#e82323' : 'black',
          alignSelf: 'flex-end',
          mt: '-10px',
        }}
      >
        {comment.length}/{COMMENT_CHAR_LIMIT}
      </Typography>

      <div className={buttonValue === 'reply' ? 'send-btn-container-end' : 'send-btn-container'}>
        {!replyingTo && (
          <FavoriteOutlinedIcon
            fontSize="large"
            sx={{ color: liked ? '#e82323' : '#c7c2c2' }}
            onClick={handleLike}
            className="clickableIcon"
          />
        )}
        {Boolean(reactions?.length) && (
          <Grid container direction="row" alignItems="center">
            <AvatarGroup
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              total={reactions?.length}
            >
              {reactions?.slice(0, 3).map((reaction) => (
                <Avatar
                  key={reaction.id}
                  alt="Remy Sharp"
                  src={`https://www.gravatar.com/avatar/${Md5.hashStr(
                    reaction.reactingConsultant.email
                  )}?s=500&r=g&d=retro`}
                />
              ))}
            </AvatarGroup>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: 'none',
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {reactions?.map((reaction) => (
                  <React.Fragment key={reaction.id}>
                    <ListItem alignItems="center">
                      <ListItemAvatar>
                        <Avatar
                          alt="Remy Sharp"
                          src={`https://www.gravatar.com/avatar/${Md5.hashStr(
                            reaction.reactingConsultant.email
                          )}?s=500&r=g&d=retro`}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={`${reaction.reactingConsultant.firstName} ${reaction.reactingConsultant.lastName}`}
                      />
                    </ListItem>
                    {reactions?.indexOf(reaction) !== reactions?.length - 1 && (
                      <Divider component="li" />
                    )}
                  </React.Fragment>
                ))}
              </List>
            </Popover>
          </Grid>
        )}
        <button className="add-btn" onClick={clickHandler}>
          {buttonValue}
        </button>
      </div>
    </div>
  );
};

export default AddComment;
